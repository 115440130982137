import { createContext, useContext } from "react";
import { MemberLayoutInfo_collections } from "../../../types/__generated__/MemberLayoutInfo";

// ===========
// App Context
// ===========
interface AppContextProps {
  memberId: number | undefined | null;
  memberTotalPoints: number | undefined | null;
  memberHasTakenTheQuiz: boolean | undefined | null;
  memberFirstName: string | undefined | null;
  memberCurrentLevel: number | undefined | null;
  memberCurrentLevelName: string | undefined | null;
  memberHasDownloadedMobileApp: boolean | undefined | null;
  memberTotalChargedPersonalOrders: number | undefined | null;
  minBottlesForFreeShipping: number | undefined | null;
  minBottlesInOrder: number | undefined | null;
  shouldWinesBeAddedToSubscription: boolean | undefined | null;
  collections: (MemberLayoutInfo_collections | null)[] | undefined | null;
  isIterableReady: boolean;
}

const appInitialState: AppContextProps = {
  memberId: null,
  memberTotalPoints: null,
  memberHasTakenTheQuiz: null,
  memberFirstName: "",
  memberCurrentLevel: null,
  memberCurrentLevelName: null,
  memberHasDownloadedMobileApp: null,
  memberTotalChargedPersonalOrders: null,
  minBottlesForFreeShipping: null,
  minBottlesInOrder: null,
  shouldWinesBeAddedToSubscription: null,
  collections: null,
  isIterableReady: false,
};

export const AppContext = createContext<AppContextProps>(appInitialState);
export const useAppContext = () => useContext(AppContext);

// ===========
// Layout Context
// ===========
interface LayoutContextProps {
  setShouldOpenBoxAndCartTray: (open: boolean) => void;
  setShowHeaderBarFromChild: (show: boolean) => void;
  showHeaderBarFromChild: boolean;
}

const layoutInitialState: LayoutContextProps = {
  setShouldOpenBoxAndCartTray: () => {},
  setShowHeaderBarFromChild: () => {},
  showHeaderBarFromChild: true,
};

export const LayoutContext = createContext<LayoutContextProps>(layoutInitialState);
export const useLayoutContext = () => useContext(LayoutContext);
