import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { colors, fonts, transition } from "../../../styles/variables";
import breakpoints from "../../../styles/breakpoints";
import { I18N_NAMESPACES, USER_HAS_ACCEPTED_COOKIE_BOX } from "../../../helpers/constants";
import { trackingEvents, useTrackEvent } from "../../../helpers/Analytics";

import Button from "../../atoms/Button";
import ButtonLink from "../../atoms/ButtonLink";
import URL_PATTERNS from "../../../urls";

const CommonButtonStyles = `
  transform: translate(0px, -5px);
  margin: 0px 5px;
  padding: 7px 10px;
`;

const StyledButton = styled(Button)`
  ${CommonButtonStyles}
`;

const StyledButtonLink = styled(ButtonLink)`
  ${CommonButtonStyles}
  display: block;
`;

const ModalCoreDiv = styled.div`
  padding: 0 !important;
  background-color: ${colors.peach};
  transition: ${transition.baseTransition};
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 1500;
  transform: translate(10px, -10px);
  min-height: 50px;
  width: calc(100% - 20px);
  max-width: 400px;
  padding: 10px;
  ${breakpoints.smDown} {
    transform: translate(-50%, -0%);
    left: 50%;
    width: 100vw;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;

const AnnouncementWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
  padding: 0 14px 0 15px;
  ${breakpoints.smDown} {
    padding: 0 20px;
    margin-top: 20.5px;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    font-family: ${fonts.fontBauRegular};
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
    ${breakpoints.smDown} {
      margin-bottom: 15px;
      text-align: center;
    }
  }
  a,
  div {
    width: 100%;
    margin-bottom: 5px;
    ${breakpoints.smDown} {
      margin-bottom: 5px;
    }
  }
`;

/**
 * Sets cache for if user has accepted the cookie box or not.
 * @param {Boolean} hasSeenBox
 * * */
const setUserHasAcceptedCookieBox = (hasSeenBox) =>
  localStorage.setItem(USER_HAS_ACCEPTED_COOKIE_BOX, hasSeenBox);

const GdprPrivacyBox = ({ acceptCookiesHandler }) => {
  const { t } = useTranslation(I18N_NAMESPACES.PRIVACY);
  const trackEvent = useTrackEvent();

  const handleAcceptCookies = () => {
    setUserHasAcceptedCookieBox(true);
    trackEvent(trackingEvents.TRACKING_EVENT_CONFIRMED_CONSENT_FOR_COOKIES, {});
    acceptCookiesHandler();
  };

  return (
    <ModalCoreDiv>
      <AnnouncementWrapper id="GdprPrivacyBox">
        <BoxContent>
          <p>{t("privacy:cookieBox.content")}</p>
          <ButtonsWrapper>
            <StyledButtonLink small ghost to={URL_PATTERNS.PRIVACY}>
              {t("privacy:cookieBox.learnMore")}
            </StyledButtonLink>
            <StyledButton small onClick={handleAcceptCookies}>
              {t("privacy:cookieBox.accept")}
            </StyledButton>
          </ButtonsWrapper>
        </BoxContent>
      </AnnouncementWrapper>
    </ModalCoreDiv>
  );
};

GdprPrivacyBox.propTypes = {
  acceptCookiesHandler: PropTypes.func.isRequired,
};

export default GdprPrivacyBox;
